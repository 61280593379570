// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import * as bootstrap from "bootstrap"
import flatpickr from "flatpickr"
import "flatpickr/dist/l10n/de"
import Choices from "choices.js"
import { Grid, html } from "gridjs"
import "chartkick/chart.js"

const lowlightWeekend = function(a, b, c, element) {
  if (element.dateObj.getDay() === 0 || element.dateObj.getDay() === 6) {
    element.className += " prevMonthDay";
  }
}

flatpickr.l10ns.de.firstDayOfWeek = 1;

function setupElements() {
  flatpickr('input[type="datetime-local"]', {
    enableTime: true,
    altInput: true,
    altFormat: 'j. F Y H:i',
    locale: flatpickr.l10ns.de,
    time_24hr: true,
    minuteIncrement: 1,
    onDayCreate: lowlightWeekend,
    wrap: false,
  });

  document.querySelectorAll('input[type="date"]').forEach((dateInput) => {
    if (dateInput.getAttribute('data-ssp-date-input') === 'true') {
      return;
    }
    dateInput.setAttribute('data-input', '');
    dateInput.className += ' flex-fill';

    const a = document.createElement('a');
    a.className = 'btn';
    a.setAttribute('data-clear', '');

    const i = document.createElement('i')
    i.className = 'bi bi-trash';
    a.appendChild(i);

    const wrap = document.createElement('div');
    wrap.className = 'flatpickr d-flex d-flex-row flex-fill';
    wrap.setAttribute('data-ssp-flatpickr', 'true');
    dateInput.replaceWith(wrap);
    wrap.appendChild(dateInput);
    wrap.appendChild(a);
    dateInput.setAttribute('data-ssp-date-input', 'true');
  });

  document.querySelectorAll('div[data-ssp-flatpickr="true"]').forEach((element) => {
    element.flatpickr({
      altInput: true,
      locale: flatpickr.l10ns.de,
      altFormat: 'j. F Y',
      onDayCreate: lowlightWeekend,
      wrap: true,
      minDate: element.childNodes[0].min,
      maxDate: element.childNodes[0].max
    });
  });

  document.querySelectorAll('select').forEach((select) => {
    if (select.getAttribute('data-ssp-choices') === 'true') {
      return;
    }

    if (!select.className.includes('flatpickr')) {
      new Choices(select, {
        classNames: {
          containerInner: Array.from(select.classList.values())
        }
      });
    }

    select.setAttribute('data-ssp-choices', 'true');
  });

  document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => {
    if (element.getAttribute('data-ssp-tooltip') === 'true') {
      return;
    }

    new bootstrap.Tooltip(element);

    element.setAttribute('data-ssp-tooltip', 'true');
  });

  document.querySelectorAll('textarea').forEach((textarea) => {
    if (textarea.getAttribute('data-ssp-enter-submit') === 'true') {
      return;
    }

    textarea.addEventListener('keydown', (event) => {
      if (event.code === 'Enter' && event.ctrlKey && event.target.form !== null) {
        event.target.form.submit()
      }
    });

    textarea.setAttribute('data-ssp-enter-submit', 'true');
  });

  document.querySelectorAll('table[data-grid-replacement]').forEach((table) => {
    if (table.getAttribute('data-grid-replaced') === 'true') {
      return;
    }

    const grid = new Grid({
      from: table,
      sort: true,
      search: 'false' !== table.getAttribute('data-grid-search'),
      pagination: 'false' !== table.getAttribute('data-grid-pagination'),
    });

    const replacement = document.querySelector(table.getAttribute('data-grid-replacement'));

    if (replacement === undefined) {
      return;
    }

    grid.render(replacement);

    // disable search and sort for columns without names
    grid.updateConfig({
      columns: grid.config.header.columns.map((header) => {
        const relevant = header.id !== '';
        return { id: header.id, name: html(header.name), sort: relevant, search: relevant };
      }),
      from: null,
      data: grid.config.storage.data()
    });
    grid.forceRender();

    table.setAttribute('data-grid-replaced', 'true');

    // it takes some time for the dom to be updated; unfortunately, the 'ready' event is not working (known bug)
    setTimeout(() => {
      replacement.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => {
        new bootstrap.Tooltip(element);
      });
    }, 400);
  });
}

setupElements();
document.addEventListener('turbo:render', (event) => {
  setupElements();
});

Turbo.setFormMode('optin')
